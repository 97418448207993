import { useState, useEffect } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../graphql/queries';

import JobsTable from '../components/JobsTable';

const List = () => {
  const [jobs, setJobs] = useState([]);

  useEffect(() => {
    fetchJobs();
  }, []);

  const fetchJobs = async team => {
    const result = await API.graphql(graphqlOperation(queries.listJobs));

    let jobs = result.data.listJobs.items;

    setJobs(jobs);
  };

  return <JobsTable jobs={jobs} />;
};

export default List;

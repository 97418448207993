const teams = {
  AMPLIFY_HOSTING: {
    image: 'https://pbs.twimg.com/media/Ds60rUaW0AACTpN.jpg',
    url: 'https://aws.amazon.com/amplify/features/#Console',
    enum: 'AMPLIFY_HOSTING',
  },
  AMPLIFY_STUDIO: {
    image:
      'https://ph-files.imgix.net/7816809b-2018-42b8-bc2c-bbe253ddaa8b.png',
    url: 'https://aws.amazon.com/amplify/features/#Admin_UI',
    enum: 'AMPLIFY_STUDIO',
  },
  AMPLIFY_CLI: {
    image:
      'https://image.slidesharecdn.com/introduction-to-aws-amplify-cl-bfce745e-1c77-4de6-9426-9a69405aaf67-1550618582-181009172400/95/introduction-to-aws-amplify-cli-17-638.jpg?cb=1539105857',
    url: 'https://docs.amplify.aws/cli',
    enum: 'AMPLIFY_CLI',
  },
  PRODUCT_MANAGER: {
    image: 'https://docs.amplify.aws/assets/ogp.jpg',
    url: 'https://docs.amplify.aws',
    enum: 'PRODUCT_MANAGER',
  },
  AMPLIFY: {
    image: 'https://docs.amplify.aws/assets/ogp.jpg',
    url: 'https://docs.amplify.aws',
    enum: 'AMPLIFY',
  },
  AMPLIFY_FLUTTER: {
    image: 'https://docs.amplify.aws/assets/ogp.jpg',
    url: 'https://docs.amplify.aws/flutter',
    enum: 'AMPLIFY_FLUTTER',
  },
  AMPLIFY_JS: {
    image: 'https://docs.amplify.aws/assets/ogp.jpg',
    url: 'https://docs.amplify.aws/js',
    enum: 'AMPLIFY_JS',
  },
  AMPLIFY_UI: {
    image: 'https://docs.amplify.aws/assets/ogp.jpg',
    url: 'https://docs.amplify.aws/ui',
    enum: 'AMPLIFY_UI',
  },
  AMPLIFY_ANDROID: {
    image: 'https://docs.amplify.aws/assets/ogp.jpg',
    url: 'https://docs.amplify.aws/android',
    enum: 'AMPLIFY_ANDROID',
  },
  AMPLIFY_IOS: {
    image: 'https://docs.amplify.aws/assets/ogp.jpg',
    url: 'https://docs.amplify.aws/ios',
    enum: 'AMPLIFY_IOS',
  },
  DEVICE_FARM: {
    image: 'https://docs.amplify.aws/assets/ogp.jpg',
    url: 'https://aws.amazon.com/device-farm/',
    enum: 'DEVICE_FARM',
  },
  APPSYNC: {
    image: 'https://docs.amplify.aws/assets/ogp.jpg',
    url: 'https://aws.amazon.com/appsync/',
    enum: 'APPSYNC',
  },
  AMPLIFY_SUPPORT: {
    image: 'https://docs.amplify.aws/assets/ogp.jpg',
    url: 'https://discord.gg/amplify',
    enum: 'AMPLIFY_SUPPORT',
  },
};

export default teams;

import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Hidden from '@mui/material/Hidden';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import { getServiceUrl } from '../helpers';
import TimeAgo from 'javascript-time-ago';
import ReactMarkdown from 'react-markdown';

// English.
import en from 'javascript-time-ago/locale/en';
import { AlertTitle } from '@mui/material';

TimeAgo.addDefaultLocale(en);

// Create formatter (English).
const timeAgo = new TimeAgo('en-US');

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const JobsTable = props => {
  const { team, jobs } = props;

  const getChipColor = job => {
    let today = new Date();
    let jobDate = new Date(job.createdAt);
    let diff = today.getTime() - jobDate.getTime();
    let days = diff / (1000 * 3600 * 24);
    days = Math.round(days);

    if (days >= 180) {
      return 'default';
    }
    if (days >= 90) {
      return 'info';
    }
    if (days >= 30) {
      return 'secondary';
    }
    if (days >= 14) {
      return 'primary';
    }
    if (days >= 0) {
      return 'success';
    }
    return 'primary';
  };

  return (
    <Box as="div" maxWidth="100%" padding="1rem" sx={{ mt: 5, mb: 20 }}>
      {!jobs.length && (
        <Alert severity="info">
          <AlertTitle>No Jobs Found for {team}</AlertTitle>I wasn't able to find
          any job postings —{' '}
          <strong>
            check out{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://amazon.jobs/en/search?base_query=Amplify"
            >
              amazon.jobs
            </a>
          </strong>
        </Alert>
      )}
      {jobs.length > 0 && (
        <>
          <Hidden mdDown>
            <Box>
              <TableContainer component={Paper}>
                <Table
                  stickyHeader
                  sx={{ minWidth: 1024 }}
                  aria-label="customized table"
                >
                  <TableHead>
                    <TableRow>
                      <Hidden lgDown>
                        <StyledTableCell>Age</StyledTableCell>
                      </Hidden>
                      <StyledTableCell>ID</StyledTableCell>
                      <Hidden lgDown>
                        <StyledTableCell>Team</StyledTableCell>
                      </Hidden>
                      <Hidden lgDown>
                        <StyledTableCell>Location</StyledTableCell>
                      </Hidden>
                      <StyledTableCell>Job Title</StyledTableCell>
                      <Hidden lgDown>
                        <StyledTableCell>Excerpt</StyledTableCell>
                      </Hidden>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {jobs.map(job => (
                      <StyledTableRow key={job.id}>
                        <Hidden lgDown>
                          <StyledTableCell>
                            <Chip
                              color={getChipColor(job)}
                              size="small"
                              label={timeAgo.format(
                                new Date(job.createdAt) - 24 * 60 * 60 * 1000,
                              )}
                            />
                          </StyledTableCell>
                        </Hidden>
                        <StyledTableCell>
                          <Button
                            component={RouterLink}
                            variant="link"
                            to={`/jobs/${job.jobId}`}
                          >
                            {job.jobId}
                          </Button>
                        </StyledTableCell>
                        <Hidden lgDown>
                          <StyledTableCell>
                            <Button
                              variant="link"
                              component={Link}
                              href={getServiceUrl(job.team)}
                            >
                              {job.team}
                            </Button>
                          </StyledTableCell>
                        </Hidden>
                        <Hidden lgDown>
                          <StyledTableCell>{job.location}</StyledTableCell>
                        </Hidden>
                        <StyledTableCell>
                          <Button
                            component={RouterLink}
                            variant="link"
                            to={`/jobs/${job.jobId}`}
                          >
                            {job.title}
                          </Button>
                        </StyledTableCell>
                        <Hidden lgDown>
                          <StyledTableCell>
                            <ReactMarkdown>{job.excerpt}</ReactMarkdown>
                          </StyledTableCell>
                        </Hidden>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Hidden>
          <Hidden lgUp>
            <Stack>
              {jobs.map(job => (
                <Paper
                  color="secondary"
                  key={job.id}
                  elevation={2}
                  sx={{ p: 2, mb: 2, textDecoration: 'none' }}
                >
                  <Typography
                    variant="h6"
                    component={RouterLink}
                    to={`/jobs/${job.jobId}`}
                    sx={{ textDecoration: 'none' }}
                  >
                    {job.title}
                  </Typography>
                  <Typography variant="body2">{job.excerpt}</Typography>
                  <Divider sx={{ mt: 2, mb: 0.5 }} />
                  <Typography variant="body2" sx={{ fontSize: '80%' }}>
                    Posted{'  '}
                    {timeAgo.format(
                      new Date(job.createdAt) - 24 * 60 * 60 * 1000,
                    )}
                    {'  '}
                    for{'  '}
                    {job.team}.
                  </Typography>
                </Paper>
              ))}
            </Stack>
          </Hidden>
        </>
      )}
    </Box>
  );
};

export default JobsTable;

import teams from './teams';

const getMediaImage = team => {
  switch (team) {
    case 'AMPLIFY_PM':
      return teams.AMPLIFY_PM.image;
    case 'AMPLIFY_HOSTING':
      return teams.AMPLIFY_HOSTING.image;
    case 'AMPLIFY_CLI':
      return teams.AMPLIFY_CLI.image;
    case 'AMPLIFY_STUDIO':
      return teams.AMPLIFY_STUDIO.image;
    default:
      return teams.AMPLIFY.image;
  }
};

const getServiceUrl = team => {
  switch (team) {
    case 'AMPLIFY_PM':
      return teams.AMPLIFY_PM.url;
    case 'AMPLIFY_HOSTING':
      return teams.AMPLIFY_HOSTING.url;
    case 'AMPLIFY_CLI':
      return teams.AMPLIFY_CLI.url;
    case 'AMPLIFY_STUDIO':
      return teams.AMPLIFY_STUDIO.url;
    default:
      return teams.AMPLIFY.url;
  }
};

const getTeam = slug => {
  switch (slug) {
    case 'studio':
      return teams.AMPLIFY_STUDIO;
    case 'hosting':
      return teams.AMPLIFY_HOSTING;
    case 'cli':
      return teams.AMPLIFY_CLI;
    case 'devicefarm':
      return teams.DEVICE_FARM;
    case 'appsync':
      return teams.APPSYNC;
    case 'amplify-flutter':
      return teams.AMPLIFY_FLUTTER;
    case 'amplify-ios':
      return teams.AMPLIFY_IOS;
    case 'amplify-android':
      return teams.AMPLIFY_ANDROID;
    case 'amplify-js':
      return teams.AMPLIFY_JS;
    case 'amplify-ui':
      return teams.AMPLIFY_UI;
    case 'product-managers':
      return teams.PRODUCT_MANAGER;
    case 'amplify-support':
      return teams.AMPLIFY_SUPPORT;
    default:
      return teams.AMPLIFY;
  }
};

export { teams, getTeam, getMediaImage, getServiceUrl };

import React from 'react';

const MobileIcon = () => {
  return (
    <svg
      width="40px"
      height="40px"
      viewBox="0 0 40 40"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>
        Icon-Architecture/32/Arch_AWS-Console-Mobile-Application _32
      </title>

      <path
        d="M2,18 L14,18 L14,17 L2,17 L2,18 Z M7,15 L15,15 L15,14 L7,14 L7,15 Z M18.424,15.751 C18.672,15.691 18.835,15.455 18.803,15.202 C18.794,15.13 18.789,15.056 18.789,14.98 C18.789,14.326 19.289,13.594 19.903,13.349 C20.193,13.232 20.461,13.187 20.703,13.187 C21.392,13.187 21.877,13.558 22.089,13.758 C22.321,13.979 22.504,14.262 22.63,14.602 C22.693,14.771 22.844,14.893 23.022,14.92 C23.201,14.947 23.381,14.877 23.492,14.734 C23.626,14.563 23.84,14.49 24.042,14.553 C24.288,14.628 24.444,14.883 24.473,15.157 C24.43,15.429 24.615,15.685 24.887,15.729 C25.307,15.797 26.012,16.036 26.012,16.868 C26.012,17.851 25.071,17.985 24.924,18 L18.752,18 C18.093,18 17.532,17.517 17.5,16.854 C17.5,16.126 18.002,15.852 18.424,15.751 L18.424,15.751 Z M18.751,19 L24.962,19 C25.671,18.945 27.012,18.429 27.012,16.868 C27.012,15.708 26.242,15.073 25.416,14.826 C25.273,14.213 24.886,13.765 24.333,13.597 C23.978,13.488 23.606,13.517 23.283,13.662 C23.14,13.427 22.971,13.216 22.776,13.032 C21.889,12.191 20.675,11.962 19.532,12.42 C18.562,12.808 17.823,13.868 17.79,14.907 C16.975,15.245 16.5,15.948 16.5,16.854 L16.504,16.983 C16.561,18.109 17.548,18.999 18.751,19 L18.751,19 Z M19,5 L20,5 L20,4 L19,4 L19,5 Z M21,5 L25,5 L25,4 L21,4 L21,5 Z M27.5,29 L16.5,29 C15.771,29 15,28.229 15,27.5 L15,26 L29,26 L29,27.5 C29,28.229 28.229,29 27.5,29 L27.5,29 Z M15,6 L15,4.5 C15,3.771 15.771,3 16.5,3 L27.5,3 C28.229,3 29,3.771 29,4.5 L29,6 L15,6 Z M27.5,2 L16.5,2 C15.215,2 14,3.215 14,4.5 L14,13 L15,13 L15,7 L29,7 L29,25 L15,25 L15,19 L14,19 L14,27.5 C14,28.785 15.215,30 16.5,30 L27.5,30 C28.785,30 30,28.785 30,27.5 L30,4.5 C30,3.215 28.785,2 27.5,2 L27.5,2 Z"
        id="AWS-Console-Mobile-Application_32_Squid"
        fill="#ffffff"
      ></path>
    </svg>
  );
};

export default MobileIcon;

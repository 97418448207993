import React from 'react';

const AmplifyIcon = () => {
  return (
    <svg
      width="40px"
      height="40px"
      viewBox="0 0 40 40"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Icon-Architecture/32/Arch_AWS-Amplify-Console_32</title>

      <path
        d="M29.0233882,30 L19.0183882,11 L21.8903882,11 L31.8933882,30 L29.0233882,30 Z M22.6343882,10.267 C22.5483882,10.103 22.3773882,10 22.1923882,10 L18.1903882,10 C18.0153882,10 17.8523882,10.092 17.7623882,10.242 C17.6713882,10.392 17.6663882,10.578 17.7483882,10.733 L28.2793882,30.733 C28.3663882,30.897 28.5363882,31 28.7213882,31 L32.7213882,31 C32.8963882,31 33.0593882,30.908 33.1493882,30.758 C33.2403882,30.608 33.2463882,30.422 33.1643882,30.267 L22.6343882,10.267 Z M22.8083882,30 L15.5713882,15.528 L16.9413882,13.077 L25.6783882,30 L22.8083882,30 Z M17.4033882,11.792 C17.3183882,11.628 17.1513882,11.524 16.9663882,11.521 L16.9593882,11.521 C16.7783882,11.521 16.6113882,11.619 16.5223882,11.777 L14.5693882,15.271 C14.4883882,15.416 14.4843882,15.591 14.5583882,15.739 L22.0523882,30.724 C22.1373882,30.893 22.3103882,31 22.5003882,31 L26.5003882,31 C26.6743882,31 26.8353882,30.909 26.9263882,30.76 C27.0173882,30.611 27.0243882,30.426 26.9443882,30.271 L17.4033882,11.792 Z M7.35438824,30 L13.8583882,18.426 L14.9333882,20.495 L11.2493882,27.3 C11.1653882,27.456 11.1693882,27.643 11.2583882,27.794 C11.3483882,27.946 11.5123882,28.039 11.6883882,28.039 L18.6873882,28.039 L19.6843882,30 L7.35438824,30 Z M19.4393882,27.312 C19.3543882,27.144 19.1823882,27.039 18.9943882,27.039 L12.5283882,27.039 L15.9393882,20.738 C16.0183882,20.592 16.0203882,20.417 15.9433882,20.27 L14.3183882,17.145 C14.2343882,16.981 14.0663882,16.878 13.8823882,16.875 L13.8753882,16.875 C13.6943882,16.875 13.5273882,16.972 13.4393882,17.13 L6.06438824,30.255 C5.97738824,30.41 5.97838824,30.599 6.06838824,30.752 C6.15838824,30.906 6.32238824,31 6.50038824,31 L20.5003882,31 C20.6733882,31 20.8353882,30.91 20.9253882,30.761 C21.0173882,30.613 21.0243882,30.428 20.9453882,30.273 L19.4393882,27.312 Z"
        id="AWS-Amplify-Console_Icon_32_Squid"
        fill="#FFFFFF"
      ></path>
    </svg>
  );
};

export default AmplifyIcon;

import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import ReactMarkdown from 'react-markdown';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';

import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../graphql/queries';
import { getServiceUrl } from '../helpers';

const Job = () => {
  let { id } = useParams();
  const [job, setJob] = useState([]);

  useEffect(() => {
    listJobs(id);
  }, [id]);

  const listJobs = async id => {
    let filters = {
      filter: {
        jobId: {
          eq: id,
        },
        status: {
          eq: 'ACTIVE',
        },
      },
    };

    const result = await API.graphql(
      graphqlOperation(queries.listJobs, filters),
    );

    let job = result.data.listJobs.items[0];
    console.log('job', result);

    setJob(job);
  };

  return (
    <Container>
      <Typography variant="h1" sx={{ mt: 5 }}>
        {job.title}
      </Typography>

      <Divider />

      <Stack direction="row" spacing={3} sx={{ mt: 2, mb: 1, fontSize: '80%' }}>
        <Chip color="info" label={job.team}></Chip>
        <Chip color="success" label={job.location}></Chip>
        <Chip label={`Created: ${job.createdAt}`}></Chip>
        <Chip label={`Updated: ${job.updatedAt}`}></Chip>
      </Stack>

      <Paper
        elevation={2}
        sx={{
          p: 5,
          marginTop: 5,
          marginBottom: 20,
        }}
      >
        <ReactMarkdown>{job.body}</ReactMarkdown>
        <Divider sx={{ mt: 4, mb: 2 }} />
        <Stack direction="row" spacing={5}>
          <Button
            size="small"
            variant="contained"
            color="success"
            component={Link}
            href={job.url}
          >
            Apply
          </Button>
          <Button
            size="small"
            variant="link"
            component={Link}
            href={getServiceUrl(job.team)}
          >
            Learn More
          </Button>
        </Stack>
      </Paper>
    </Container>
  );
};

export default Job;

import * as React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import MainMenu from './components/MainMenu';

import Job from './pages/Job';
import Home from './pages/Home';
import Team from './pages/Team';

import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';

Amplify.configure(awsconfig);

export default function App() {
  return (
    <Router>
      <MainMenu />
      <Container>
        <Box as="div">
          <Switch>
            <Route path="/jobs/:id">
              <Job />
            </Route>
            <Route path="/teams/:slug">
              <Team />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </Box>
      </Container>

      <Box height="1rem">
        <Divider
          size="small"
          label="This website is not officially affiliated with Amazon or AWS in any way."
        />
      </Box>
      <Container>
        <Box>
          Built with Amplify, managed with Amplify CLI &amp; Amplify Studio, and
          hosted on Amplify Hosting.
        </Box>
      </Container>
    </Router>
  );
}

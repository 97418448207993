import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../graphql/queries';

import JobsTable from '../components/JobsTable';
import { getTeam } from '../helpers';

const Team = () => {
  let { slug } = useParams();
  const [jobs, setJobs] = useState([]);
  console.log('slug', slug);
  let team = getTeam(slug);
  console.log('team', team);

  useEffect(() => {
    const fetchJobs = async slug => {
      var filters = {};

      if (slug) {
        filters = {
          filter: {
            team: {
              eq: team.enum,
            },
            status: {
              eq: 'ACTIVE',
            },
          },
        };
      }

      const result = await API.graphql(
        graphqlOperation(queries.listJobs, filters),
      );

      console.log('result', result);

      let jobs = result.data?.listJobs.items;

      setJobs(jobs);
    };
    fetchJobs(slug);
  }, [team, slug]);

  return <JobsTable team={slug} jobs={jobs} />;
};

export default Team;

import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';

import AmplifyIcon from '../components/Icons/AmplifyIcon';
import DeviceFarmIcon from '../components/Icons/DeviceFarmIcon';
import AppSyncIcon from '../components/Icons/AppSyncIcon';
import ToolsIcon from '../components/Icons/ToolsIcon';
import SupportIcon from '../components/Icons/SupportIcon';
import MobileIcon from '../components/Icons/MobileIcon';
import { Divider } from '@mui/material';

const MainMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [amplifyAnchorEl, setAmplifyAnchorEl] = React.useState(null);
  const amplifyOpen = Boolean(amplifyAnchorEl);
  const handleAmplifyClick = event => {
    setAmplifyAnchorEl(event.currentTarget);
  };
  const handleAmplifyClose = () => {
    setAmplifyAnchorEl(null);
  };

  const [frameworkAnchorEl, setFrameworkAnchorEl] = React.useState(null);
  const frameworkOpen = Boolean(frameworkAnchorEl);
  const handleFrameworkClick = event => {
    setFrameworkAnchorEl(event.currentTarget);
  };
  const handleFrameworkClose = () => {
    setFrameworkAnchorEl(null);
  };

  return (
    <AppBar position="static" color="primary">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Link
            sx={{
              display: 'flex',
              color: '#fff',
              textDecoration: 'none',
              lineHeight: '40px',
              flexGrow: 1,
            }}
            href="/"
          >
            <AmplifyIcon
              sx={{
                display: {
                  xs: 'none',
                  md: 'flex',
                },
                mr: 1,
                maxWidth: { lg: '32px' },
              }}
            />
            <Typography
              variant="h6"
              noWrap
              sx={{
                ml: 1,
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
                lineHeight: '45px',
              }}
            >
              Amplify Jobs
            </Typography>
          </Link>

          <Box sx={{ flexGrow: 0, display: { md: 'none' } }}>
            <Button
              variant="outlined"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
              startIcon={<MenuIcon />}
              sx={{ color: 'white' }}
            >
              Job Openings
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem onClick={handleClose}>Amplify Hosting</MenuItem>
              <MenuItem onClick={handleClose}>Amplify Studio</MenuItem>
              <MenuItem onClick={handleClose}>Amplify CLI</MenuItem>
              <Divider />
              <MenuItem onClick={handleClose}>Amplify JS</MenuItem>
              <MenuItem onClick={handleClose}>Amplify Flutter</MenuItem>
              <MenuItem onClick={handleClose}>Amplify iOS</MenuItem>
              <MenuItem onClick={handleClose}>Amplify Android</MenuItem>
              <MenuItem onClick={handleClose}>Amplify UI</MenuItem>
              <Divider />
              <MenuItem onClick={handleClose}>Device Farm</MenuItem>
              <MenuItem onClick={handleClose}>AppSync</MenuItem>
              <MenuItem onClick={handleClose}>Product Management</MenuItem>
              <MenuItem onClick={handleClose}>Support</MenuItem>
            </Menu>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', lg: 'flex' } }}>
            <Stack direction="row" spacing={2}>
              <Button
                startIcon={<AmplifyIcon />}
                sx={{ color: 'white' }}
                id="amplify-menu-btn"
                aria-controls={amplifyOpen ? 'amplify-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={amplifyOpen ? 'true' : undefined}
                onClick={handleAmplifyClick}
              >
                Amplify
              </Button>

              <Menu
                id="amplify-menu"
                anchorEl={amplifyAnchorEl}
                open={amplifyOpen}
                onClose={handleAmplifyClose}
                MenuListProps={{
                  'aria-labelledby': 'amplify-menu-btn',
                }}
              >
                <MenuItem
                  onClick={handleAmplifyClose}
                  component={RouterLink}
                  to="/teams/hosting"
                >
                  Hosting
                </MenuItem>
                <MenuItem
                  onClick={handleAmplifyClose}
                  component={RouterLink}
                  to="/teams/studio"
                >
                  Studio
                </MenuItem>
                <MenuItem
                  onClick={handleAmplifyClose}
                  component={RouterLink}
                  to="/teams/cli"
                >
                  CLI
                </MenuItem>
              </Menu>
              <Button
                startIcon={<ToolsIcon />}
                sx={{ color: 'white' }}
                id="framework-menu-btn"
                aria-controls={frameworkOpen ? 'framework-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={frameworkOpen ? 'true' : undefined}
                onClick={handleFrameworkClick}
              >
                Framework
              </Button>
              <Menu
                id="framework-menu"
                anchorEl={frameworkAnchorEl}
                open={frameworkOpen}
                onClose={handleFrameworkClose}
                MenuListProps={{
                  'aria-labelledby': 'framework-menu-btn',
                }}
              >
                <MenuItem
                  onClick={handleFrameworkClose}
                  component={RouterLink}
                  to="/teams/amplify-js"
                >
                  JS
                </MenuItem>
                <MenuItem
                  onClick={handleFrameworkClose}
                  component={RouterLink}
                  to="/teams/amplify-flutter"
                >
                  Flutter
                </MenuItem>
                <MenuItem
                  onClick={handleFrameworkClose}
                  component={RouterLink}
                  to="/teams/amplify-ios"
                >
                  iOS
                </MenuItem>
                <MenuItem
                  onClick={handleFrameworkClose}
                  component={RouterLink}
                  to="/teams/amplify-android"
                >
                  Android
                </MenuItem>
                <MenuItem
                  onClick={handleFrameworkClose}
                  component={RouterLink}
                  to="/teams/amplify-ui"
                >
                  UI
                </MenuItem>
              </Menu>
              <Button
                component={RouterLink}
                to="/teams/devicefarm"
                startIcon={<DeviceFarmIcon />}
                sx={{ color: 'white' }}
              >
                DeviceFarm
              </Button>
              <Button
                component={RouterLink}
                to="/teams/appsync"
                startIcon={<AppSyncIcon />}
                sx={{ color: 'white' }}
              >
                AppSync
              </Button>
              <Button
                component={RouterLink}
                to="/teams/support"
                startIcon={<SupportIcon />}
                sx={{ color: 'white' }}
              >
                Support
              </Button>
              <Button
                component={RouterLink}
                to="/teams/product"
                startIcon={<MobileIcon />}
                sx={{ color: 'white' }}
              >
                Product
              </Button>
            </Stack>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default MainMenu;

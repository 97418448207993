import React from 'react';

const ToolsIcon = () => {
  return (
    <svg
      width="40px"
      height="40px"
      viewBox="0 0 40 40"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Icon-Architecture/32/Arch_AWS-Tools-and-SDK_32</title>

      <path
        d="M21,32.49075 L21,29.50075 L20,29.50075 L20,32.49075 L9,26.13975 L9,13.84975 L12.757,15.93775 L13.243,15.06375 L9.54,13.00575 L20.5,7.06975 L31.46,13.00675 L27.757,15.06375 L28.243,15.93775 L32,13.85075 L32,26.13975 L21,32.49075 Z M32.737,12.56075 L20.738,6.06075 C20.59,5.97975 20.41,5.97975 20.262,6.06075 L8.262,12.56075 C8.101,12.64875 8,12.81675 8,13.00075 L8,26.42875 C8,26.60775 8.096,26.77275 8.25,26.86175 L20.25,33.78975 C20.327,33.83375 20.414,33.85675 20.5,33.85675 C20.586,33.85675 20.673,33.83375 20.75,33.78975 L32.749,26.86175 C32.903,26.77275 32.999,26.60775 32.999,26.42875 L32.999,13.00075 C32.999,12.81675 32.898,12.64875 32.737,12.56075 L32.737,12.56075 Z M21,26.66375 L21,19.79275 L26,16.90275 L26,23.97675 L21,26.66375 Z M15,16.90275 L20,19.79275 L20,26.66375 L15,23.97675 L15,16.90275 Z M20.5,13.61875 L25.258,16.17675 L20.5,18.92675 L15.742,16.17675 L20.5,13.61875 Z M26.736,15.83575 L20.736,12.61175 C20.59,12.53275 20.41,12.53275 20.264,12.61175 L14.264,15.83575 C14.102,15.92275 14,16.09175 14,16.27575 L14,24.27575 C14,24.45975 14.102,24.62975 14.264,24.71675 L20.264,27.94075 C20.337,27.98075 20.419,28.00075 20.5,28.00075 C20.581,28.00075 20.663,27.98075 20.736,27.94075 L26.736,24.71675 C26.898,24.62975 27,24.45975 27,24.27575 L27,16.27575 C27,16.09175 26.898,15.92275 26.736,15.83575 L26.736,15.83575 Z"
        id="AWS-Tools-and-SDK_Icon_32_Squid"
        fill="#FFFFFF"
      ></path>
    </svg>
  );
};

export default ToolsIcon;
